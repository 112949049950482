import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'

import 'alpinejs'
import "@fontsource/annie-use-your-telescope/400.css"
import 'tailwindcss/tailwind.css'
import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  new SweetScroll();
})

// Makes the initMap function Google's API requires
// available in Stimulus controllers
window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}