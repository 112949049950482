import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, Mousewheel } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, Mousewheel])

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      direction: 'vertical',
      paginationClickable: true,
      loop: true,
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      autoplay: {
        delay: 5000
      },
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      }

    })
  }
}
